// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-doctor-landing-page-doctor-landing-page-js": () => import("./../../../src/components/DoctorLandingPage/DoctorLandingPage.js" /* webpackChunkName: "component---src-components-doctor-landing-page-doctor-landing-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-details-js": () => import("./../../../src/pages/about-us-details.js" /* webpackChunkName: "component---src-pages-about-us-details-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-app-navigation-index-js": () => import("./../../../src/pages/app/navigation/index.js" /* webpackChunkName: "component---src-pages-app-navigation-index-js" */),
  "component---src-pages-app-navigation-twin-store-js": () => import("./../../../src/pages/app/navigation/twin-store.js" /* webpackChunkName: "component---src-pages-app-navigation-twin-store-js" */),
  "component---src-pages-app-navigation-twin-works-js": () => import("./../../../src/pages/app/navigation/twin-works.js" /* webpackChunkName: "component---src-pages-app-navigation-twin-works-js" */),
  "component---src-pages-app-navigation-wbdt-care-app-plan-js": () => import("./../../../src/pages/app/navigation/wbdt-care-app-plan.js" /* webpackChunkName: "component---src-pages-app-navigation-wbdt-care-app-plan-js" */),
  "component---src-pages-book-pack-js": () => import("./../../../src/pages/book-pack.js" /* webpackChunkName: "component---src-pages-book-pack-js" */),
  "component---src-pages-book-pack-referral-js": () => import("./../../../src/pages/book-pack-referral.js" /* webpackChunkName: "component---src-pages-book-pack-referral-js" */),
  "component---src-pages-book-pack-trial-js": () => import("./../../../src/pages/book-pack-trial.js" /* webpackChunkName: "component---src-pages-book-pack-trial-js" */),
  "component---src-pages-book-pack-webinar-js": () => import("./../../../src/pages/book-pack-webinar.js" /* webpackChunkName: "component---src-pages-book-pack-webinar-js" */),
  "component---src-pages-cgm-status-js": () => import("./../../../src/pages/cgm-status.js" /* webpackChunkName: "component---src-pages-cgm-status-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-csv-login-js": () => import("./../../../src/pages/csv-login.js" /* webpackChunkName: "component---src-pages-csv-login-js" */),
  "component---src-pages-csv-upload-js": () => import("./../../../src/pages/csv-upload.js" /* webpackChunkName: "component---src-pages-csv-upload-js" */),
  "component---src-pages-diabetes-quiz-js": () => import("./../../../src/pages/diabetes-quiz.js" /* webpackChunkName: "component---src-pages-diabetes-quiz-js" */),
  "component---src-pages-doctor-consult-js": () => import("./../../../src/pages/doctor-consult.js" /* webpackChunkName: "component---src-pages-doctor-consult-js" */),
  "component---src-pages-doctor-consult-scheduling-js": () => import("./../../../src/pages/doctor-consult-scheduling.js" /* webpackChunkName: "component---src-pages-doctor-consult-scheduling-js" */),
  "component---src-pages-doctor-consult-successful-js": () => import("./../../../src/pages/doctor-consult-successful.js" /* webpackChunkName: "component---src-pages-doctor-consult-successful-js" */),
  "component---src-pages-doctor-doctor-detail-page-js": () => import("./../../../src/pages/doctor/doctor-detail-page.js" /* webpackChunkName: "component---src-pages-doctor-doctor-detail-page-js" */),
  "component---src-pages-doctor-doctor-portal-js": () => import("./../../../src/pages/doctor/doctor-portal.js" /* webpackChunkName: "component---src-pages-doctor-doctor-portal-js" */),
  "component---src-pages-doctor-login-js": () => import("./../../../src/pages/doctor/login.js" /* webpackChunkName: "component---src-pages-doctor-login-js" */),
  "component---src-pages-doctor-referral-home-js": () => import("./../../../src/pages/doctor-referral-home.js" /* webpackChunkName: "component---src-pages-doctor-referral-home-js" */),
  "component---src-pages-doctor-referral-login-js": () => import("./../../../src/pages/doctor-referral-login.js" /* webpackChunkName: "component---src-pages-doctor-referral-login-js" */),
  "component---src-pages-doctor-wbdt-care-doctorlp-plan-js": () => import("./../../../src/pages/doctor/wbdt-care-doctorlp-plan.js" /* webpackChunkName: "component---src-pages-doctor-wbdt-care-doctorlp-plan-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-metabolism-assesment-ty-js": () => import("./../../../src/pages/metabolism-assesment-ty.js" /* webpackChunkName: "component---src-pages-metabolism-assesment-ty-js" */),
  "component---src-pages-metabolism-assessment-js": () => import("./../../../src/pages/metabolism-assessment.js" /* webpackChunkName: "component---src-pages-metabolism-assessment-js" */),
  "component---src-pages-payment-status-js": () => import("./../../../src/pages/payment-status.js" /* webpackChunkName: "component---src-pages-payment-status-js" */),
  "component---src-pages-quiz-diabetes-lp-js": () => import("./../../../src/pages/quiz/diabetes-lp.js" /* webpackChunkName: "component---src-pages-quiz-diabetes-lp-js" */),
  "component---src-pages-quiz-diabetes-result-js": () => import("./../../../src/pages/quiz/diabetes-result.js" /* webpackChunkName: "component---src-pages-quiz-diabetes-result-js" */),
  "component---src-pages-quiz-diabetes-ty-js": () => import("./../../../src/pages/quiz/diabetes-ty.js" /* webpackChunkName: "component---src-pages-quiz-diabetes-ty-js" */),
  "component---src-pages-referral-quiz-js": () => import("./../../../src/pages/referral-quiz.js" /* webpackChunkName: "component---src-pages-referral-quiz-js" */),
  "component---src-pages-reversal-thanking-js": () => import("./../../../src/pages/reversal-thanking.js" /* webpackChunkName: "component---src-pages-reversal-thanking-js" */),
  "component---src-pages-reversal-vs-management-js": () => import("./../../../src/pages/reversal-vs-management.js" /* webpackChunkName: "component---src-pages-reversal-vs-management-js" */),
  "component---src-pages-reversal-webinar-free-js": () => import("./../../../src/pages/reversal-webinar-free.js" /* webpackChunkName: "component---src-pages-reversal-webinar-free-js" */),
  "component---src-pages-reversal-webinar-js": () => import("./../../../src/pages/reversal-webinar.js" /* webpackChunkName: "component---src-pages-reversal-webinar-js" */),
  "component---src-pages-reversal-webinar-lp-fb-js": () => import("./../../../src/pages/reversal-webinar-lp-fb.js" /* webpackChunkName: "component---src-pages-reversal-webinar-lp-fb-js" */),
  "component---src-pages-reversal-webinar-lp-js": () => import("./../../../src/pages/reversal-webinar-lp.js" /* webpackChunkName: "component---src-pages-reversal-webinar-lp-js" */),
  "component---src-pages-reversal-webinar-lp-yt-2-js": () => import("./../../../src/pages/reversal-webinar-lp-yt2.js" /* webpackChunkName: "component---src-pages-reversal-webinar-lp-yt-2-js" */),
  "component---src-pages-reversal-webinar-lp-yt-3-js": () => import("./../../../src/pages/reversal-webinar-lp-yt3.js" /* webpackChunkName: "component---src-pages-reversal-webinar-lp-yt-3-js" */),
  "component---src-pages-reversal-webinar-lp-yt-js": () => import("./../../../src/pages/reversal-webinar-lp-yt.js" /* webpackChunkName: "component---src-pages-reversal-webinar-lp-yt-js" */),
  "component---src-pages-reviewed-journals-js": () => import("./../../../src/pages/reviewed-journals.js" /* webpackChunkName: "component---src-pages-reviewed-journals-js" */),
  "component---src-pages-risk-quiz-js": () => import("./../../../src/pages/risk-quiz.js" /* webpackChunkName: "component---src-pages-risk-quiz-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-testimonial-details-js": () => import("./../../../src/pages/testimonial-details.js" /* webpackChunkName: "component---src-pages-testimonial-details-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-twin-consultation-js": () => import("./../../../src/pages/twin-consultation.js" /* webpackChunkName: "component---src-pages-twin-consultation-js" */),
  "component---src-pages-twin-doctor-details-js": () => import("./../../../src/pages/twin-doctor-details.js" /* webpackChunkName: "component---src-pages-twin-doctor-details-js" */),
  "component---src-pages-twin-doctors-js": () => import("./../../../src/pages/twin-doctors.js" /* webpackChunkName: "component---src-pages-twin-doctors-js" */),
  "component---src-pages-twin-landing-ty-js": () => import("./../../../src/pages/twin-landing-ty.js" /* webpackChunkName: "component---src-pages-twin-landing-ty-js" */),
  "component---src-pages-twin-reversal-webinar-js": () => import("./../../../src/pages/twin-reversal-webinar.js" /* webpackChunkName: "component---src-pages-twin-reversal-webinar-js" */),
  "component---src-pages-twin-webinar-lp-js": () => import("./../../../src/pages/twin-webinar-lp.js" /* webpackChunkName: "component---src-pages-twin-webinar-lp-js" */),
  "component---src-pages-wbdt-care-doctor-plan-js": () => import("./../../../src/pages/wbdt-care-doctor-plan.js" /* webpackChunkName: "component---src-pages-wbdt-care-doctor-plan-js" */),
  "component---src-pages-wbdt-care-enterprise-plan-js": () => import("./../../../src/pages/wbdt-care-enterprise-plan.js" /* webpackChunkName: "component---src-pages-wbdt-care-enterprise-plan-js" */),
  "component---src-pages-wbdt-care-plan-js": () => import("./../../../src/pages/wbdt-care-plan.js" /* webpackChunkName: "component---src-pages-wbdt-care-plan-js" */),
  "component---src-pages-wbdt-care-plan-option-js": () => import("./../../../src/pages/wbdt-care-plan-option.js" /* webpackChunkName: "component---src-pages-wbdt-care-plan-option-js" */),
  "component---src-pages-wbdt-care-plan-pricing-js": () => import("./../../../src/pages/wbdt-care-plan-pricing.js" /* webpackChunkName: "component---src-pages-wbdt-care-plan-pricing-js" */),
  "component---src-pages-wbdt-care-remarketing-plan-js": () => import("./../../../src/pages/wbdt-care-remarketing-plan.js" /* webpackChunkName: "component---src-pages-wbdt-care-remarketing-plan-js" */),
  "component---src-pages-wbdt-care-webinar-plan-js": () => import("./../../../src/pages/wbdt-care-webinar-plan.js" /* webpackChunkName: "component---src-pages-wbdt-care-webinar-plan-js" */),
  "component---src-pages-webinar-bookpack-sales-js": () => import("./../../../src/pages/webinar-bookpack-sales.js" /* webpackChunkName: "component---src-pages-webinar-bookpack-sales-js" */),
  "component---src-pages-webinar-reversal-js": () => import("./../../../src/pages/webinar/reversal.js" /* webpackChunkName: "component---src-pages-webinar-reversal-js" */)
}

