import queryString from "query-string";
import {
  add,
  format,
  addDays,
  parseISO,
  isBefore,
  isSameDay,
  setHours,
  startOfDay,
  parse,
} from "date-fns";

// constants
import constant from "../constants";

export const formatNumber = (num, currency = "INR") => {
  if (currency === "INR") {
    return "₹" + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const getTimeStamp = () => {
  let d = new Date();
  let timeStamp =
    d.toLocaleString() + " " + d.toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1]; // eslint-disable-line no-useless-escape
  return timeStamp;
};

export const getUtmDataFromLS = () => {
  let data = {
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_term: "",
    utm_content: "",
  };

  if (typeof window !== "undefined") {
    const utmUpdatedAt = localStorage.getItem("utmUpdatedAt");
    if (utmUpdatedAt) {
      // console.log("utmUpdatedAt-1", utmUpdatedAt);
      const updatedAt = new Date(parseInt(utmUpdatedAt));
      // console.log("utmUpdatedAt-2", updatedAt);
      const nowDate = add(updatedAt, { hours: 24 });
      // const nowDate = add(updatedAt, { seconds: 30 });
      // console.log("updatedAt, nowDate", nowDate, updatedAt);

      const today = new Date();
      if (nowDate > today) {
        data["utm_source"] = localStorage.getItem("utmSource") || "";
        data["utm_medium"] = localStorage.getItem("utmMedium") || "";
        data["utm_campaign"] = localStorage.getItem("utmCampaign") || "";
        data["utm_term"] = localStorage.getItem("utmTerm") || "";
        data["utm_content"] = localStorage.getItem("utmContent") || "";
      }
    }

    // data["utm_source"] = localStorage.getItem("utmSource") || "";
    // data["utm_medium"] = localStorage.getItem("utmMedium") || "";
    // data["utm_campaign"] = localStorage.getItem("utmCampaign") || "";
    // data["utm_term"] = localStorage.getItem("utmTerm") || "";
    // data["utm_content"] = localStorage.getItem("utmContent") || "";
  }
  return data;
};

export const saveUTM = location => {
  let params = queryString.parse(location);
  //   console.log(params, typeof params);
  let utmSource = params["utm_source"] || "";
  let utmMedium = params["utm_medium"] || "";
  let utmCampaign = params["utm_campaign"] || "";
  let utmTerm = params["utm_term"] || "";
  let utmContent = params["utm_content"] || "";

  if (utmSource !== "" && utmSource !== null) {
    if (typeof window !== "undefined") {
      const today = new Date();
      localStorage.setItem("utmSource", utmSource);
      localStorage.setItem("utmMedium", utmMedium);
      localStorage.setItem("utmCampaign", utmCampaign);
      localStorage.setItem("utmTerm", utmTerm);
      localStorage.setItem("utmContent", utmContent);
      localStorage.setItem("utmUpdatedAt", today.getTime());
    }
  }
};

export const isBrowser = () => typeof window !== "undefined";

export const replaceHashWithComma = value => {
  return value.replace(/#/g, ",");
};

export const noOfDayAndMonthConversion = val => {
  return val < 30
    ? `within ${val} Days`
    : val === 30
    ? `within ${Math.floor(val / 30)} Month`
    : val > 30 && val < 365
    ? `within ${Math.floor(val / 30)} Months`
    : val > 365
    ? `within ${Math.floor(val / 365)} Year`
    : "";
};

// This function returns the plancode and planprice for the bookpack page
// based on the pincode category ie GOLD(serviceable), SILVER(remote) & PLATINUM(non-serviceable)

// update the value based on "categoryVal" key.
// ie if categoryVal = true then return GOLD/SILVER plancode and planprice else it will return the default values.

export const validateServiceabilityBookPack = async (
  category,
  annualPlan,
  installmentPlan
) => {
  if (
    category === constant.BOOKPACK_CATEGORIES.TIER_I ||
    category === constant.BOOKPACK_CATEGORIES.TIER_II
  ) {
    const annualData = annualPlan[category].split("#");
    const installmentData = installmentPlan[category].split("#");

    return {
      planCode: annualData[0],
      planPrice: Number(annualData[1]),
      perMonthPlanPrice: Math.round(annualData[1] / 12),
      categoryVal: true,
      firstInstallmentPrice: Number(installmentData[2]),
    };
  } else {
    return {
      planCode: annualPlan.planCode,
      planPrice: annualPlan.planPrice,
      perMonthPlanPrice: Math.round(annualPlan.planPrice / 12),
      categoryVal: false,
    };
  }
};

export const splitName = (name = "") => {
  const [firstName, ...lastName] = name.split(" ").filter(Boolean);
  return {
    firstName: firstName,
    lastName: lastName.join(" "),
  };
};

export const splitPlanCodeBookPack = val => {
  return val.planCode.split("#");
};

// conversion of date string(Mon Mar 20 2023 13:02:59 GMT+0530 (India Standard Time)) format into YYYY-MM-DD
export const convertIstDateFormat = val => {
  const date = new Date(val);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

// Convert username and password into base64
export const authBase64Encoding = (username, password) => {
  const authString = `${username}:${password}`;
  const base64AuthString = btoa(authString);

  // console.log(base64AuthString); // Outputs: ZXhhbXBsZVVzZXI6ZXhhbXBsZVBhc3N3b3Jk
  return base64AuthString;
};

// create unique 10 digit number
export const uniqueRandomNumber = () => {
  const randomNum = Math.floor(1000000000 + Math.random() * 9000000000);
  // console.log(randomNum);
  return randomNum;
};

// create unique sessionId
export const getSessionId = () => {
  const sessionId = Math.random().toString(36).substring(2, 8);
  // console.log(sessionId);
  return sessionId;
};

// Conversion of date "YYYY-MM-DD" and day "SUNDAY" into "DD SUN" format
export const extractDateAndDay = (date, day) => {
  // Extract the day part from the date
  // Format:- date("2023-10-22"), day("SATURDAY")
  const dateParts = date.split("-");
  const dayPart = dateParts[dateParts.length - 1];

  // Format the result as "day dayShort"
  const formattedValue = `${dayPart} ${day.substring(0, 3)}`;

  return formattedValue; // Output: "27 FRI"
};

// convert this date and time format 2023-10-28T13:30:00 0530 into DD-MM-YYYY, hh:mm A
export const convertUstDateTimeFormat = inputDate => {
  const date = new Date(inputDate);
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formattedDate = date.toLocaleString("en-IN", options);
  return formattedDate;
};

// convert this date and time format 2023-10-28T13:30:00+0530 into 2023-10-28 13:30:00
export const convertUstDateTimeFormat2 = data => {
  // Parse the original timestamp
  const originalDate = parseISO(data);

  // Adjust the date by subtracting four days
  const adjustedDate = addDays(originalDate, -4);

  // Format the adjusted date and time as a string
  const formattedTimestamp = format(adjustedDate, "yyyy-MM-dd HH:mm:ss");

  return formattedTimestamp;
};

// Function to generate webinar landing page slots :: (pageUrl: twin-webinar-lp)
export const generateWebinarLandingPageSlots = timings => {
  const slots = [];
  const now = new Date();

  let date = now;
  while (slots.length < 7) {
    const dayOfWeek = date.getDay();
    const slotInfo = timings.find(d => d.day === dayOfWeek);
    if (slotInfo) {
      slots.push({
        id: slots.length,
        date,
        startTime: slotInfo.startTime,
        endTime: slotInfo.endTime,
      });
    }
    date = addDays(date, 1);
  }

  return slots;
};

// Function to filter slots for webinar landing page based on current time and 4 PM cutoff :: (pageUrl: twin-webinar-lp)
export const filterWebinarLandingPageSlots = slots => {
  const now = new Date();
  const cutoffTime = setHours(startOfDay(now), 16); // 4:00 PM today

  return slots.filter(slot => {
    const slotDate = startOfDay(slot.date);

    // Exclude past dates
    if (isBefore(slotDate, startOfDay(now))) return false;

    // Exclude same-day slots after 4 PM
    if (isSameDay(slotDate, now) && isBefore(cutoffTime, now)) return false;

    return true;
  });
};

// Function to format date and time for webinar landing page :: (pageUrl: twin-webinar-lp)
export const formatWebinarLandingPageSlotLabel = slot => {
  const dateFormat = "EEEE, MMMM d";
  const timeFormat = "h:mm a";

  const formattedDate = format(slot.date, dateFormat);

  const startTime = parse(slot.startTime, "HH:mm", slot.date);
  const endTime = parse(slot.endTime, "HH:mm", slot.date);

  const formattedStartTime = format(startTime, timeFormat);
  const formattedEndTime = format(endTime, timeFormat);

  return `${formattedDate}, ${formattedStartTime} - ${formattedEndTime}`;
};
